import classNames from 'classnames'
import type { FunctionComponent } from 'react'

import type { BrandColor } from '../../../types/color'
import styles from './star.module.scss'

export const Star: FunctionComponent<Props & JSX.IntrinsicElements['svg']> = ({
    percent = 100,
    color,
    width = 40,
    height = 40,
    ...restOfProps
}) => {
    if (percent < 0 || percent > 100) {
        throw new Error('Percent must be between 0 and 100')
    }

    return (
        <svg
            className={classNames(styles[`fill-${color}`])}
            width={width}
            height={height}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...restOfProps}
        >
            <path
                opacity='0.25'
                d='M12 0L15.5267 7.1459L23.4127 8.2918L17.7063 13.8541L19.0534 21.7082L12 18L4.94658 21.7082L6.29366 13.8541L0.587322 8.2918L8.47329 7.1459L12 0Z'
            />
            <path
                d='M12 0L15.5267 7.1459L23.4127 8.2918L17.7063 13.8541L19.0534 21.7082L12 18L4.94658 21.7082L6.29366 13.8541L0.587322 8.2918L8.47329 7.1459L12 0Z'
                style={{
                    clipPath: `inset(0 calc(100% - ${percent}%) 0 0)`,
                }}
            />
        </svg>
    )
}
interface Props {
    // The pixel width of the star
    width?: number
    // The pixel height of the star
    height?: number
    // The percentage of the star to fill
    percent: number
    // The color of the star
    color: BrandColor
}
