import type { FunctionComponent } from 'react'

import { RawAnchor } from '../../components/anchor'
import { AppStoreRating } from '../../components/app-store-rating'
import { Image } from '../../components/image'
import { Section } from '../../components/layout/section'
import type { SectionOfPressBannersFragment } from '../../graphql/fragments/SectionOfPressBannersFragment.graphql'
import { useGetSectionOfPressBanners } from '../../graphql/hooks/section-of-press-banners'
import type { EntryMeta } from '../../types/sys'
import styles from './section-of-press-banners.module.scss'

export const SectionOfPressBanners: FunctionComponent<
    SectionOfPressBannersFragment
> = ({ sys, type, ...restOfProps }) => {
    if (restOfProps?.icons) {
        return (
            <SectionOfPressBannersResolved
                sys={sys}
                type={type}
                {...restOfProps}
            />
        )
    }

    return <SelfFetchingSectionOfPressBanners sys={sys} type={type} />
}

const SelfFetchingSectionOfPressBanners: FunctionComponent<EntryMeta> = ({
    sys,
}) => {
    const { data } = useGetSectionOfPressBanners({
        variables: {
            id: sys.id,
        },
    })

    if (!data.section) {
        return null
    }

    return <SectionOfPressBannersResolved {...data.section} />
}

const SectionOfPressBannersResolved: FunctionComponent<
    SectionOfPressBannersFragment
> = ({ icons, appStoreRating, numberOfReviews }) => {
    return (
        <Section className={styles.section} padHorizontal={false}>
            <div className={styles.content}>
                <div className={styles.logos}>
                    {(icons?.items ?? []).map(
                        ({ sys: { id }, action, image }) => {
                            const icon = image?.svgSource ? (
                                <Image
                                    source={image.svgSource}
                                    fit='contain'
                                    className={styles.logo}
                                />
                            ) : null

                            if (!action || !('url' in action)) {
                                return (
                                    <div className={styles.link} key={id}>
                                        {icon}
                                    </div>
                                )
                            }

                            return (
                                <RawAnchor
                                    key={id}
                                    href={action.url}
                                    target={id}
                                    className={styles.link}
                                >
                                    {icon}
                                </RawAnchor>
                            )
                        }
                    )}
                    {appStoreRating && numberOfReviews && (
                        <div className={styles.rating}>
                            <AppStoreRating
                                value={appStoreRating}
                                numberOfReviews={numberOfReviews}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Section>
    )
}

export default SectionOfPressBanners
